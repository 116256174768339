import toast from '@uilib/business-components/ToastContainer/Toast';
import i18n from 'Services/i18n';
import Filters from 'Services/Filters';
import FlexContainer from '@uilib/core/FlexContainer/FlexContainer';
import Text from 'uilib-wrappers/text';

export default function summarize({ assignee, status, comment }) {
    if (
        status?.success?.skippedExecutionOnSomeObjects ||
        assignee?.success?.skippedExecutionOnSomeObjects ||
        comment?.success?.skippedExecutionOnSomeObjects
    ) {
        toast.warning(i18n('WARNING_SKIPPED_EXECUTION_ON_SOME_OBJECTS'), { autoClose: 6000 });
    } else if (assignee?.success && status?.success) {
        toast.success(i18n('INCIDENTS_STATUS_AND_ASSIGNEE_UPDATED'), {
            autoClose: 3000,
        });
    } else if (assignee?.success) {
        toast.success(i18n('SUCCESSFULLY_ASSIGNED_INCIDENTS'), {
            autoClose: 3000,
        });
    } else if (status?.success) {
        toast.success(
            <FlexContainer>
                <Text color="currentcolor">SUCCESSFULLY_CHANGED_STATUS_OF_INCIDENTS</Text>
                <Text color="currentcolor" fontWeight="bold">
                    {Filters.incidentStatusLabel[status.value]}
                </Text>
            </FlexContainer>,
            { autoClose: 3000 }
        );
    }

    if (status && !status?.success) {
        toast.error(i18n('STATUS_COULDNT_BE_CHANGED'), {
            autoClose: 6000,
        });
    }

    if (assignee && !assignee?.success) {
        toast.error(i18n('ASSIGNEE_COULDNT_BE_CHANGED'), {
            autoClose: 6000,
        });
    }

    if (comment && !comment?.success) {
        toast.error(i18n('COMMENT_COULDNT_BE_ADDED'), {
            autoClose: 6000,
        });
    }
}

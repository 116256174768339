import { useState, Fragment, useMemo } from 'react';

import toast from '@uilib/business-components/ToastContainer/Toast';
import FlexContainer from '@uilib/core/FlexContainer/FlexContainer';
import Link from 'uilib-wrappers/link';
import Tag from 'uilib-wrappers/tag';
import Tooltip from 'uilib-wrappers/tooltip';

import TagsEditModal from 'Bricks/tags-edit-modal';
import { BACKEND, withBackend } from 'Services/backend';
import { createSimpleSelectionBody } from 'Bricks/Helpers';
import i18n from 'Services/i18n';

import './index.css';

function TagsRow(props) {
    const [tagsEditOpen, setTagsEditOpen] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);

    const tags = useMemo(() => (Array.isArray(props.value) ? props.value : []), [props.value]);

    function handleTagsEditChange(event, diff) {
        const requestBody = createSimpleSelectionBody([props.tagsObjectId]);
        requestBody.objectType = props.tagsObjectType;
        requestBody.added = diff.added;
        requestBody.removed = diff.removed;
        if (props.tagHelpers?.moduleId) {
            requestBody.moduleId = props.tagHelpers.moduleId;
        }
        if (props.tagHelpers?.groupId) {
            requestBody.groupId = props.tagHelpers.groupId;
        }
        BACKEND.post('tags/update', requestBody, props.componentUuid)
            .success((response) => {
                props.onChange(event);
            })
            .failure((respose) => {
                toast.error(i18n('TAG_CREATION_FAILED'), { autoClose: 3000 });
            })
            .execute();
    }

    function handleTagsEditClick(event) {
        const requestBody = createSimpleSelectionBody([props.tagsObjectId]);
        requestBody.objectType = props.tagsObjectType;
        if (props.tagHelpers?.moduleId) {
            requestBody.moduleId = props.tagHelpers.moduleId;
        }
        if (props.tagHelpers?.groupId) {
            requestBody.groupId = props.tagHelpers.groupId;
        }
        BACKEND.post('tags/list', requestBody, props.componentUuid)
            .success((response) => {
                setSelectedTags(response);
                setTagsEditOpen(true);
            })
            .execute();
    }

    function handleTagsEditClose(event) {
        setTagsEditOpen(false);
    }

    return (
        <Fragment>
            {props.value && (
                <FlexContainer className={props.cell ? 'ei-tags--cell' : ''}>
                    {tags.map((tag) => {
                        return (
                            <Tooltip key={tag.id} interactive={true} placement="auto" tooltipContent={tag.name}>
                                <div>
                                    <Tag>{tag.localized_name || tag.name}</Tag>
                                </div>
                            </Tooltip>
                        );
                    })}
                </FlexContainer>
            )}
            <TagsEditModal
                open={tagsEditOpen}
                tags={selectedTags}
                onChange={handleTagsEditChange}
                onClose={handleTagsEditClose}
            />
            {!props.cell && <Link disabled={props.disabled} onClick={handleTagsEditClick} text="SELECT_TAGS" />}
        </Fragment>
    );
}

export default withBackend(TagsRow);

import { useState, useEffect } from 'react';

import { BACKEND, withBackend } from 'Services/backend';
import i18n from 'Services/i18n';

import Progress from 'Bricks/Progress';
import { createSimpleSelectionBody } from 'Bricks/Helpers';

import Textarea from '@uilib/business-components/Textarea/Textarea';
import Button from 'uilib-wrappers/button';
import Modal from 'uilib-wrappers/modal';

function CommentEditor(props) {
    const [activeComment, setActiveComment] = useState(props.activeComment);
    const [newComment, setNewComment] = useState(props?.activeComment?.commentValue ?? '');
    const [progress, setProgress] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(true);

    function onCommentSave() {
        const comment = newComment.trim();
        if (!props.isMulti) {
            if (comment !== activeComment.commentValue) {
                setProgress(true);
                BACKEND.put(
                    activeComment.commentId === 0 ? 'comments/add' : 'comment/update',
                    {
                        ...createSimpleSelectionBody([props.objectId]),
                        commentId: activeComment.commentId,
                        comment,
                        objectId: props.objectId,
                        objectType: props.objectType,
                        ...props.additionalDataForRequestBody,
                    },
                    props.componentUuid
                )
                    .success(() => {
                        props.onChange();
                    })
                    .always(() => {
                        props.onClose();
                    })
                    .execute();
            }
        } else {
            setSaveDisabled(true);
            props.onSave(comment);
            props.onClose();
        }
    }

    function onDispose() {
        setNewComment(props.activeComment.commentValue);
        props.onClose();
    }

    function onCommentUpdate(updatedComment) {
        setNewComment(updatedComment);
    }

    function handleHidden() {
        setProgress(false);
    }

    useEffect(() => {
        if (props.isOpen) {
            setSaveDisabled(true);
            setProgress(false);
        }
    }, [props.isOpen]);

    useEffect(() => {
        setActiveComment(props.activeComment);
        setNewComment(props.activeComment.commentValue);
    }, [props.activeComment]);

    useEffect(() => {
        setSaveDisabled(activeComment.commentValue === newComment.trim());
    }, [newComment]);

    return (
        <Modal
            show={props.isOpen}
            title="ENTER_YOUR_COMMENT_BELOW"
            onDispose={onDispose}
            onHidden={handleHidden}
            buttons={[
                <Button
                    id="eid-comment-button-save"
                    type="primary"
                    text="SAVE"
                    onClick={onCommentSave}
                    disabled={saveDisabled || progress}
                />,
                <Button id="eid-comment-button-cancel" type="secondary" text="CANCEL" onClick={onDispose} />,
            ]}
        >
            <Textarea
                value={newComment}
                onChange={onCommentUpdate}
                maxLength={window.serverInfo.dbColumnsLength.comments.commentValue}
                placeholder={i18n('ENTER_OPTIONAL_COMMENT_HERE_MAX_CHARACTERS', {
                    count: window.serverInfo.dbColumnsLength.comments.commentValue,
                })}
            />
            <Progress showProgress={progress} />
        </Modal>
    );
}

CommentEditor.defaultProps = {
    activeComment: { commentId: 0, commentValue: '' },
};

export default withBackend(CommentEditor);

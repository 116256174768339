import { useState, useEffect, useContext } from 'react';

import Select from 'uilib-wrappers/select';
import i18n from 'Services/i18n';

import { AppContext } from 'Services/AppContext';

function WebConsoleUserSelect(props) {
    const [selectedUser, setSelectedUser] = useState();
    const [usersList, setUsersList] = useState({ tree: [], raw: [] });
    const [menuPortal, setMenuPortal] = useState(null);
    const { username, userUuid, users } = useContext(AppContext);

    useEffect(() => {
        setMenuPortal(props.menuPortalTarget || document.getElementById(props.modalId));

        const usersList = users.map((user) => ({ id: user.uuid, value: user.uuid, label: user.name }));

        const assignToMe = {
            id: userUuid,
            value: userUuid,
            label: i18n('ASSIGN_TO_ME', { username }),
        };

        const specialUsers = {
            options: window.serverInfo.constants.knownGuids.map((knownGuid) => ({
                id: knownGuid.guid,
                value: knownGuid.guid,
                label: knownGuid.name,
            })),
            withSeparator: true,
        };

        setUsersList({
            tree: [
                ...(props.withAssignToMe
                    ? [
                          {
                              ...assignToMe,
                          },
                          {
                              options: usersList,
                              withSeparator: true,
                          },
                      ]
                    : usersList),
                ...(props.additionalFilterOptions?.withSpecialUsers ? [specialUsers] : []),
            ],
            raw: [
                ...(props.withAssignToMe ? [assignToMe] : []),
                ...(props.additionalFilterOptions?.withSpecialUsers ? specialUsers.options : []),
                ...usersList,
            ],
        });
    }, [username, userUuid, users]);

    useEffect(() => {
        if (usersList.tree.length && props.selectedUser?.value !== selectedUser?.value) {
            const selectedUserDetails =
                props.selectedUser?.value && usersList.raw.find((user) => user.value === props.selectedUser.value);
            props.onChange(selectedUserDetails);
            setSelectedUser(selectedUserDetails);
        }
    }, [props.selectedUser, usersList]);

    return (
        <Select
            className={props.className}
            menuPortalTarget={menuPortal}
            allowEmpty={props.allowEmpty}
            defaultValue={props.defaultUser}
            options={usersList.tree}
            placeholder={props.placeholder || 'SELECT_WITH_DOTS'}
            value={selectedUser}
            onChange={props.onChange}
            disabled={props.disabled}
        />
    );
}

export default WebConsoleUserSelect;

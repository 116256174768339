import { useEffect, useState } from 'react';
import Theme from '@uilib/business-components/_utils/theme';
import Button from 'uilib-wrappers/button';
import Modal from 'uilib-wrappers/modal';
import Progress from 'Bricks/Progress';

import GroupsPane, { DEFAULT_ACTIVE_GROUP } from 'Bricks/complex-table-view/groups-pane';

const ACCESS_GROUP_MODAL_THEME = {
    Modal: {
        maxWidth: '70%',
        maxHeight: '70%',
        width: '45%',
        height: '70%',
        innerPadding: '0px',
        simple: {
            borderTopColor: 'white',
        },
    },
};

function AccessGroupModal(props) {
    const defaultSelectedGroupIdState = props.preselectedGroupId || DEFAULT_ACTIVE_GROUP;

    const [selectedGroupId, setSelectedGroupId] = useState(defaultSelectedGroupIdState);
    const [isLoading, setIsLoading] = useState(false);

    function handleSubmit() {
        if (isLoading) {
            return;
        }

        setIsLoading(true);
        const accessGroupChange = props.onChange(selectedGroupId);
        if (accessGroupChange instanceof Promise) {
            accessGroupChange.then(props.close);
        } else {
            props.close();
        }
    }

    function handleCancel() {
        setSelectedGroupId(defaultSelectedGroupIdState);
        props.close();
    }

    function handleGroupsChange(treeGroupId) {
        setSelectedGroupId(treeGroupId);
    }

    useEffect(() => {
        setSelectedGroupId(defaultSelectedGroupIdState);
    }, [defaultSelectedGroupIdState]);

    useEffect(() => {
        if (props.show) {
            setIsLoading(false);
        }
    }, [props.show]);

    return (
        <Theme.Provider theme={ACCESS_GROUP_MODAL_THEME}>
            <Modal
                id={props.id}
                type="simple"
                show={props.show}
                title="PLEASE_SELECT_A_GROUP"
                onDispose={handleCancel}
                buttons={[
                    <Button
                        id="access-group-modal-ok"
                        key={`access-group-modal-ok-${isLoading}`}
                        type="primary"
                        text="OK"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    />,
                    <Button
                        id="access-group-modal-cancel"
                        key="access-group-modal-cancel"
                        type="secondary"
                        text="CANCEL"
                        onClick={handleCancel}
                    />,
                ]}
            >
                <GroupsPane
                    accessRight={window.serverInfo.constants.accessRight.AR_Groups_Computers_View}
                    groups={props.groups}
                    defaultActive={selectedGroupId}
                    disableGroupChangeSubscribe
                    onChange={handleGroupsChange}
                />
                <Progress showProgress={isLoading} />
            </Modal>
        </Theme.Provider>
    );
}

export default AccessGroupModal;

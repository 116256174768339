import { Component, Fragment } from 'react';
import { BACKEND, withBackend } from 'Services/backend';
import i18n from 'Services/i18n';
import Filters from 'Services/Filters';
import GroupFilterService from 'Services/group-filter';
import { Navigator } from 'Services';
import {
    updateAccessGroupSubmenuOnSelectionChange,
    showAffectedRowsInfo,
    isAffectedRowsLessThanSelectedRows,
} from 'Bricks/Helpers';
import AccessGroupModal from 'Bricks/access-group-modal';
import ComplexTableView from 'Bricks/complex-table-view';
import { TABLE_COLUMN_WIDTH } from 'Bricks/Grid/Grid';
import GridCells from 'Bricks/Grid/Cells';
import { AppContext } from 'Services/AppContext';
import createIncidentesCommanding, { COMMANDING_MODE } from './commanding';
import NavigationBarService from 'Services/navigation-bar';
import StatusAssigneeModal from './status-assignee-modal';
import ReopenModal from './reopen-modal';
const INCIDENT_SEVERITY = window.serverInfo.constants.incidentSeverity;
const INCIDENT_STATUS = window.serverInfo.constants.incidentStatus;

const EPX_COMPATIBILITY_MODE = window.serverInfo.isEpxIncidentManagement;

function getColumns() {
    return [
        {
            field: 'name',
            name: 'NAME',
            width: TABLE_COLUMN_WIDTH.WIDE,
            cellComponent: GridCells.IncidentNameActionCell,
        },
        {
            field: 'description',
            name: 'DESCRIPTION',
            sorting: { disabled: EPX_COMPATIBILITY_MODE },
        },
        {
            field: 'severity',
            name: 'SEVERITY',
            cellComponent: GridCells.IncidentSeverityCell,
            filter: Filters.incidentSeverity,
            width: TABLE_COLUMN_WIDTH.NARROW,
            padding: true,
        },
        {
            field: 'status',
            name: 'STATUS',
            cellComponent: GridCells.IncidentStatusCell,
            filter: Filters.incidentStatus,
            width: TABLE_COLUMN_WIDTH.X_NARROW,
            padding: true,
        },
        {
            field: 'authorName',
            name: 'AUTHOR',
        },
        {
            field: 'accessGroup',
            name: 'ACCESS_GROUP',
        },
        {
            field: 'createTime',
            name: 'CREATE_TIME',
            filter: Filters.whenSeenOrLocalDate,
        },
        {
            field: 'assigneeName',
            name: 'ASSIGNEE',
        },
        {
            field: 'lastUpdate',
            name: 'LAST_UPDATE',
            filter: Filters.whenSeenOrLocalDate,
        },
        {
            field: 'detections',
            name: 'DETECTIONS',
            width: TABLE_COLUMN_WIDTH.X_NARROW,
            centered: true,
        },
        {
            field: 'computers',
            name: 'COMPUTERS',
            width: TABLE_COLUMN_WIDTH.X_NARROW,
            centered: true,
        },
        ...(EPX_COMPATIBILITY_MODE
            ? []
            : [
                  {
                      field: 'executables',
                      name: 'EXECUTABLES',
                      width: TABLE_COLUMN_WIDTH.X_NARROW,
                      centered: true,
                  },
                  {
                      field: 'processes',
                      name: 'PROCESSES',
                      width: TABLE_COLUMN_WIDTH.X_NARROW,
                      centered: true,
                  },
              ]),
        {
            field: 'statusReason',
            name: 'STATUS_REASON',
            filter: Filters.incidentStatusReason,
            width: TABLE_COLUMN_WIDTH.X_NARROW,
            padding: true,
        },
    ];
}

const requiredFields = [
    'id',
    'current',
    'severity',
    'investigatedByEset',
    'accessGroupId',
    'accessGroupPath',
    'assigneeName',
    'assigneeUuid',
];

function resolveLocalFiltersConfiguration() {
    const URL_FILTERS_EXIST = this.props.$stateParams?.urlFilters !== undefined;
    const URL_FILTERS = this.props.$stateParams?.urlFilters || {};

    return {
        dashboard: {
            visible: URL_FILTERS_EXIST,
            label: 'SELECTED_INCIDENTS',
            action: (event) => {
                Navigator.go(event, 'console.incidents');
            },
        },

        filters: {
            AND: [
                {
                    status: {
                        type: 'shadow',
                        active: true,
                        operator: 'NE',
                        value: INCIDENT_STATUS.deleted,
                    },
                },
                {
                    accessGroupId: {
                        fixed: true,
                        type: 'groupsTree',
                        label: 'ACCESS_GROUP',
                        additionalFilterOptions: { disableLabelSplit: true, onIncidentPage: true },
                    },
                },
                {
                    OR: [
                        // Column field extended by suffix to create unique id.
                        {
                            'severity:high': {
                                type: 'button',
                                active: URL_FILTERS.incidentSeverity?.EQ === INCIDENT_SEVERITY.high || undefined,
                                value: INCIDENT_SEVERITY.high,
                                icon: Filters.incidentSeverityIcon.get(INCIDENT_SEVERITY.high)[0],
                                color: Filters.incidentSeverityIcon.get(INCIDENT_SEVERITY.high)[1],
                                tooltipText: 'SHOW_HIGH_SEVERITY_INCIDENTS',
                            },
                        },
                        {
                            'severity:medium': {
                                type: 'button',
                                active: URL_FILTERS.incidentSeverity?.EQ === INCIDENT_SEVERITY.medium || undefined,
                                value: INCIDENT_SEVERITY.medium,
                                icon: Filters.incidentSeverityIcon.get(INCIDENT_SEVERITY.medium)[0],
                                color: Filters.incidentSeverityIcon.get(INCIDENT_SEVERITY.medium)[1],
                                tooltipText: 'SHOW_MEDIUM_SEVERITY_INCIDENTS',
                            },
                        },
                        {
                            'severity:low': {
                                type: 'button',
                                active: URL_FILTERS.incidentSeverity?.EQ === INCIDENT_SEVERITY.low || undefined,
                                value: INCIDENT_SEVERITY.low,
                                icon: Filters.incidentSeverityIcon.get(INCIDENT_SEVERITY.low)[0],
                                color: Filters.incidentSeverityIcon.get(INCIDENT_SEVERITY.low)[1],
                                tooltipText: 'SHOW_LOW_SEVERITY_INCIDENTS',
                            },
                        },
                    ],
                },
                {
                    OR: [
                        // Column field extended by suffix to create unique id.
                        {
                            'status:open': {
                                type: 'button',
                                active: URL_FILTERS.incidentStatus?.EQ === INCIDENT_STATUS.open || undefined,
                                value: INCIDENT_STATUS.open,
                                icon: Filters.incidentStatusIcon.get(INCIDENT_STATUS.open)[0],
                                color: Filters.incidentStatusIcon.get(INCIDENT_STATUS.open)[1],
                                tooltipText: 'SHOW_OPENED_INCIDENTS',
                            },
                        },
                        {
                            'status:inProgress': {
                                type: 'button',
                                active: URL_FILTERS.incidentStatus?.EQ === INCIDENT_STATUS.inProgress || undefined,
                                value: INCIDENT_STATUS.inProgress,
                                icon: Filters.incidentStatusIcon.get(INCIDENT_STATUS.inProgress)[0],
                                color: Filters.incidentStatusIcon.get(INCIDENT_STATUS.inProgress)[1],
                                tooltipText: 'SHOW_INPROGRESS_INCIDENTS',
                            },
                        },
                        {
                            'status:closed': {
                                type: 'button',
                                active: URL_FILTERS.incidentStatus?.EQ === INCIDENT_STATUS.closed || undefined,
                                value: INCIDENT_STATUS.closed,
                                icon: Filters.incidentStatusIcon.get(INCIDENT_STATUS.closed)[0],
                                color: Filters.incidentStatusIcon.get(INCIDENT_STATUS.closed)[1],
                                tooltipText: 'SHOW_CLOSED_INCIDENTS',
                            },
                        },
                    ],
                },
                {
                    name: {
                        type: 'text',
                        label: 'NAME',
                        blankValues: !EPX_COMPATIBILITY_MODE,
                    },
                },
                {
                    authorUuid: {
                        type: 'userSelect',
                        label: 'AUTHOR',
                        additionalFilterOptions: { withSpecialUsers: true },
                    },
                },
                {
                    'authorUuid:authorNameShadow': {
                        type: 'shadow',
                        active: URL_FILTERS.authorUuid?.EQ !== undefined,
                        value: URL_FILTERS.authorUuid?.EQ,
                        label: 'AUTHOR',
                    },
                },
                {
                    createTime: {
                        type: 'timestamp',
                        label: 'CREATE_TIME',
                        blankValues: !EPX_COMPATIBILITY_MODE,
                    },
                },
                {
                    'createTime:dateFrom': {
                        type: 'shadow',
                        active: URL_FILTERS.dateFrom !== undefined || URL_FILTERS.creationTime !== undefined,
                        operator: 'GE',
                        value: URL_FILTERS.dateFrom || URL_FILTERS.creationTime,
                    },
                },
                {
                    'createTime:dateTo': {
                        type: 'shadow',
                        active: URL_FILTERS.dateTo !== undefined,
                        operator: 'LE',
                        value: URL_FILTERS.dateTo,
                    },
                },
                {
                    assigneeUuid: {
                        type: 'userSelect',
                        label: 'ASSIGNEE',
                    },
                },
                {
                    lastUpdate: {
                        type: 'timestamp',
                        label: 'LAST_UPDATE',
                        blankValues: !EPX_COMPATIBILITY_MODE,
                    },
                },
                {
                    detections: {
                        type: 'number',
                        label: 'DETECTIONS',
                        blankValues: !EPX_COMPATIBILITY_MODE,
                    },
                },
                {
                    computers: {
                        type: 'number',
                        label: 'COMPUTERS',
                        blankValues: !EPX_COMPATIBILITY_MODE,
                    },
                },
                ...(EPX_COMPATIBILITY_MODE
                    ? []
                    : [
                          {
                              executables: {
                                  type: 'number',
                                  label: 'EXECUTABLES',
                                  blankValues: !EPX_COMPATIBILITY_MODE,
                              },
                          },
                          {
                              processes: {
                                  type: 'number',
                                  label: 'PROCESSES',
                                  blankValues: !EPX_COMPATIBILITY_MODE,
                              },
                          },
                          {
                              description: {
                                  type: 'text',
                                  label: 'DESCRIPTION',
                                  blankValues: !EPX_COMPATIBILITY_MODE,
                              },
                          },
                      ]),
                { incidentSize: { type: 'shadow' } },
                {
                    statusReason: {
                        type: 'select',
                        label: 'STATUS_REASON',
                        operators: { initial: 1, items: ['NE', 'EQ'] },
                        values: {
                            initial: 0,
                            items: Object.entries(
                                window.serverInfo.columnsFilters.incidentStatusReason
                            ).map(([id, name]) => ({ id: Number(id), name })),
                        },
                    },
                },
            ],
        },

        presets: {
            visible: !URL_FILTERS_EXIST,
            default: [
                {
                    name: 'INCIDENTS_ASSIGNED_TO_ME',
                    filters: {
                        assigneeUuid: {
                            visible: true,
                            active: true,
                            operator: 'EQ',
                            value: this.context?.userUuid,
                        },
                    },
                },
            ],
        },
    };
}

class IncidentsView extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.gridApi = null;

        this.state = {
            isAccessGroupModalOpen: false,
            isStatusAssigneeModalOpen: false,
            isReopenModalOpen: false,
            selectedEntities: [],
        };

        //---------------------------------------------------------
        // EI-GRID CONFIGURATION
        //---------------------------------------------------------
        this.gridOptions = {
            resetLocalFilters: this.props.$stateParams.urlFilters !== undefined,
            requiredFields,
            columns: getColumns(),
            //csvPostProcessors: { detailLink: { columnName: 'detailPage', urlTemplate: 'machines/{id}/details' } },
            isRowStatusInfo: (entity) => {
                return entity.current;
            },
            onRowClicked: this.onRowClicked,
            onRowSelectionChanged: this.onRowSelectionChanged,
            onRegisterApi: (gridApi) => {
                this.gridApi = gridApi;
            },
            url: 'incidents',
        };

        const accessGroupSubmenuClbk = () => {
            this.setState({ isAccessGroupModalOpen: true });
        };

        this.commandingConfiguration = createIncidentesCommanding.call(this, {
            accessGroupSubmenuClbk,
            mode: COMMANDING_MODE.GRID,
            reload: this.reload,
            componentUuid: this.props.componentUuid,
            onStatusAssigneeModalOpen: this.handleStatusAssigneeModalOpen,
            onReopenModalOpen: this.handleReopenModalOpen,
        });
    } // c-tor

    componentDidMount() {
        const URL_FILTERS = this.props.$stateParams.urlFilters || {};

        GroupFilterService.disable();

        if (URL_FILTERS?.dashboardType) {
            NavigationBarService.clearBreadcrumbs();

            if (URL_FILTERS.authorName?.EQ !== undefined) {
                NavigationBarService.setTitle(i18n('INCIDENTS_CREATED_BY', { author: URL_FILTERS.authorName?.EQ }));
            } else if (URL_FILTERS.incidentSeverity?.EQ !== undefined) {
                NavigationBarService.setTitle(
                    i18n('INCIDENTS_WITH_SEVERITY', {
                        severity: i18n(
                            window.serverInfo.columnsFilters.incidentSeverity[URL_FILTERS.incidentSeverity?.EQ]
                        ),
                    })
                );
            } else if (URL_FILTERS.incidentStatus?.EQ !== undefined) {
                NavigationBarService.setTitle(
                    i18n('INCIDENTS_WITH_STATUS', {
                        status: i18n(window.serverInfo.columnsFilters.incidentStatus[URL_FILTERS.incidentStatus?.EQ]),
                    })
                );
            } else {
                if (URL_FILTERS.dateFromLocal !== undefined || URL_FILTERS.dateToLocal !== undefined) {
                    NavigationBarService.addPlainCrumb(`[${URL_FILTERS.dateFromLocal}] - [${URL_FILTERS.dateToLocal}]`);
                }
                NavigationBarService.setTitle('ESET_INSPECT_INCIDENTS');
            }
        }
    }

    componentWillUnmount() {
        GroupFilterService.enable();
    }

    reload = () => {
        this.gridApi.reloadDataFromSession();
    };

    onRowClicked = (event, entity) => {
        Navigator.go(event, 'console.incident.graph', { incidentId: entity.id });
    };

    onRowSelectionChanged = (entities) => {
        updateAccessGroupSubmenuOnSelectionChange(this.gridApi.commanding, entities);
        this.setState({ selectedEntities: entities });
    };

    changeStatus = async (status, { reason, assignee, comment }) => {
        let success = false;
        await this.gridApi.updateBackendWithSelectionEx(
            'post',
            reason !== undefined ? `incidents/status/${status}/reason/${reason}` : `incidents/status/${status}`,
            {
                ...(assignee !== undefined && { assignee }),
                ...(comment && { comment }),
            },
            (response, selectedCount = 1) => {
                if (response && response.affectedRows !== undefined && response.affectedRows < selectedCount) {
                    success = false;
                    showAffectedRowsInfo(selectedCount, response.affectedRows);
                } else {
                    success = {
                        skippedExecutionOnSomeObjects: response.skippedExecutionOnSomeObjects,
                    };
                }
            },
            () => {
                success = false;
            }
        );
        return success;
    };

    handleAccessGroupChange = (selectedGroupId) => {
        const requestBody = this.gridApi.getSelectionRequestBody();
        requestBody['accessGroupId'] = selectedGroupId;
        const selectedRows = this.gridApi.getSelectedCountFromState();

        return BACKEND.post('incidents/access_group', requestBody, this.props.componentUuid)
            .success((response) => {
                isAffectedRowsLessThanSelectedRows(response, selectedRows);
                this.gridApi.reloadDataFromSession();
            })
            .execute();
    };

    handleAccessGroupModalClose = () => {
        this.setState({ isAccessGroupModalOpen: false });
    };

    handleStatusAssigneeModalClose = () => {
        this.setState({ isStatusAssigneeModalOpen: false });
    };

    handleStatusAssigneeModalOpen = (isStatusAssigneeModalUniversal) => {
        this.setState({ isStatusAssigneeModalOpen: true, isStatusAssigneeModalUniversal });
    };

    handleReopenModalClose = () => {
        this.setState({ isReopenModalOpen: false });
    };

    handleReopenModalOpen = () => {
        this.setState({ isReopenModalOpen: true });
    };

    render() {
        return (
            <Fragment>
                <ComplexTableView
                    title="INCIDENTS"
                    storage={this.props.$stateParams.urlFilters !== undefined ? 'dashboardIncidents' : 'incidents'}
                    tagsObjectType={window.serverInfo.constants.dbObject.incident}
                    options={this.gridOptions}
                    localFiltersOptions={resolveLocalFiltersConfiguration.call(this)}
                    commandingConfiguration={this.commandingConfiguration}
                    linkFilters={this.props.$stateParams.filters}
                />

                <AccessGroupModal
                    show={this.state.isAccessGroupModalOpen}
                    onChange={this.handleAccessGroupChange}
                    close={this.handleAccessGroupModalClose}
                />
                <StatusAssigneeModal
                    isStatusAssigneeModalUniversal={this.state.isStatusAssigneeModalUniversal}
                    show={this.state.isStatusAssigneeModalOpen}
                    close={this.handleStatusAssigneeModalClose}
                    entities={this.state.selectedEntities}
                    changeStatus={this.changeStatus}
                    reload={this.reload}
                />
                <ReopenModal
                    show={this.state.isReopenModalOpen}
                    close={this.handleReopenModalClose}
                    entities={this.state.selectedEntities}
                    changeStatus={this.changeStatus}
                    reload={this.reload}
                />
            </Fragment>
        );
    }
}

export { getColumns, resolveLocalFiltersConfiguration, requiredFields };

export default withBackend(IncidentsView);

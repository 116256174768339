import IconNumericOne from '@uilib/assets-business-icons/IconNumericOne';
import IconNumericTwo from '@uilib/assets-business-icons/IconNumericTwo';
import IconNumericThree from '@uilib/assets-business-icons/IconNumericThree';
import IconActionFilter from '@uilib/assets-business-icons/IconActionFilter';
import IconNumericNew from '@uilib/assets-business-icons/IconNumericNew';
import IconTimeRunning from '@uilib/assets-business-icons/IconTimeRunning';
import IconTimeHistory from '@uilib/assets-business-icons/IconTimeHistory';
import IconMenuMoreVertical from '@uilib/assets-business-icons/IconMenuMoreVertical';

import React, { Component, Fragment, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { BACKEND, withBackend } from 'Services/backend';
import Checkbox from '@uilib/business-components/Checkbox/Checkbox';
import ButtonGroup from '@uilib/business-components/ButtonGroup';
import Spacer from '@uilib/core/Spacer/Spacer';
import Button from 'uilib-wrappers/button';
import Dropdown from 'uilib-wrappers/dropdown';
import FormGroup from 'uilib-wrappers/form-group';
import Tooltip from 'uilib-wrappers/tooltip';

import DropdownButton from 'Bricks/dropdown-button';
import IncidentSelectionModal from 'Bricks/incident-selection-modal';
import { EVENT, EventNames } from 'Services/Eventing';
import { Authorization } from 'Services';
import i18n from 'Services/i18n';

import FileUploader from 'Bricks/file-uploader';

import './index.scss';
import EmptyIcon from 'Bricks/empty-icon';

// React API for commanding popup window:
//
// Put following code in node/component in  OnCOntextMenu property callback:
//
// <div .... onContextMenu={(e)=>{COMMANDING_CTRL.onContextMenuClicked(e, this.commanding/*ForCurrentView*/)}}>
//   ....
// </div>

/**
 * Basic and base object for all Commanding context types.
 * Any context object deriving from this object can implement doCommand method to achieve different behaviours.
 * doCommand method must have the same input parameters as execute method.
 */
class CommandingContextBasic {
    /**
     * @callback BasicContextCallback
     * @param {Event} event DOM event.
     * @param {Object} command Command object that invoked this callback function.
     */

    /**
     * @typedef {Object} BasicCommand
     * @property {String} name
     * @property {BasicContextCallback} clbk Callback function to invoke when selected command is executed.
     */

    /**
     * @typedef {Object.<String, BasicCommand>} BasicCommandsHash
     * @example
     * commands = {
     *     B1: { name: "Function label 1", clbk: onFunc1 },
     *     B2: { name: "Function label 2", clbk: onFunc2 },
     *     B3: { name: "Function label 3", clbk: onFunc3 },
     *     B4: { name: "Function label 4", clbk: onFunc4 },
     *     B5: { name: "Function label 5", clbk: onFunc5 },
     * }
     */

    /**
     * Initializes commanding context.
     * @param {BasicCommandsHash} commands Object definining Commands managed by this commanding context.
     */
    constructor(configuration) {
        this.commands = configuration.commands;
        this.toolbar = configuration.toolbar;
        this.context = configuration.context;
        this.groupContext = configuration.groupContext;
        this.specialContext = null;

        this.dropdowns = this.context !== undefined ? [this.context] : [];
    }

    setCommandState = (commands, flagKey, flagValue) => {
        if (this.commands) {
            if (commands.length) {
                for (const command of commands) {
                    if (command) {
                        if (this.commands[command]) {
                            this.commands[command][flagKey] = flagValue;
                        }
                    }
                }
            } else {
                // disable all commands
                for (let key in this.commands) {
                    this.commands[key][flagKey] = flagValue;
                }
            }
        }
    };

    /**
     * Executes selected UI command.
     * @param {Event} event DOM Event
     * @param {String} command key name
     */
    execute = (event, command) => {
        if (!this.commands[command].disabled) {
            // invoke "polymorphic" method
            this.doCommand(event, command);
        }
    };

    // Invokes callback function associated with selected command.
    doCommand = (event, command) => {
        if (!this.commands[command].disabled) {
            EVENT.publish(EventNames.COMMANDING_ACTION_CALLED_EVENT, { name: this.commands[command].name, count: 1 });
            if (this.commands[command].postAction) {
                this.commands[command].postData = JSON.stringify({
                    bearer: Authorization.token,
                    body: this.commands[command].clbk(event, this.commands[command]),
                });
            } else {
                this.commands[command].clbk(event, this.commands[command]);
            }
        }
    };

    /**
     * Hides selected command or all commands.
     * @param {...String} [commands] Names of the commands to hide or null to hide all commands.
     */
    hide = (...commands) => {
        this.setCommandState(commands, 'hidden', true);
    };

    /**
     * Shows selected command or all commands.
     * @param {...String} [commands] Names of the commands to show or null to show all commands.
     */
    show = (...commands) => {
        this.setCommandState(commands, 'hidden', false);
    };

    /**
     * Disables selected command or all commands.
     * @param {...String} [commands] Names of the commands to disable or null to disable all commands.
     */
    disable = (...commands) => {
        this.setCommandState(commands, 'disabled', true);
    };

    /**
     * Enables selected command or all commands.
     * @param {...String} [commands] Names of the commands to enable or null to enable all commands.
     */
    enable = (...commands) => {
        this.setCommandState(commands, 'disabled', false);
    };

    /**
     * Set tooltip for selected command or all commands.
     * @param {String} [tooltip] Tooltip content or null to disable tooltip.
     * @param {...String} [commands] Names of the commands to set tooltip to or null to set tooltip to all commands.
     */
    setTooltip = (tooltip, ...commands) => {
        this.setCommandState(commands, 'tooltip', tooltip);
    };

    activateContext(showGroupContext) {
        this.dropdowns = [showGroupContext ? this.groupContext : this.context];
    }

    attachCommand(command, addToContext, addToGroupContext, data) {
        if (this.commands) {
            if (this.commands[command] === undefined) {
                if (this.context && addToContext) {
                    this.context.layout = this.context.layout.concat([[command]]);
                }

                if (this.groupContext && addToGroupContext) {
                    if (Array.isArray(this.groupContext.layout)) {
                        this.groupContext.layout = this.groupContext.layout.concat([[command]]);
                    } else {
                        for (const id in this.groupContext.layout) {
                            if (!this.groupContext.layout[id].some((item) => item[0] === command)) {
                                this.groupContext.layout[id] = this.groupContext.layout[id].concat([[command]]);
                            }
                        }
                    }
                }
            }
            this.commands[command] = data;
        }
    }

    updateCommand(command, field, value) {
        if (this.commands && this.commands[command] !== undefined) {
            this.commands[command][field] = value;
        }
    }

    attachFilteringOptions(header, layout, disabled) {
        if (this.commands) {
            this.specialContext = { header, layout, disabled };
        }
    }

    removeFilteringOptions() {
        this.specialContext = null;
    }
}

/**
 * Commanding context that uses selection to enable/disable certain commands.
 */
class CommandingContextSelection extends CommandingContextBasic {
    constructor(configuration) {
        super(configuration);

        this.updateSelection();
    }

    doCommand = (event, command) => {
        EVENT.publish(EventNames.COMMANDING_ACTION_CALLED_EVENT, {
            name: this.commands[command].name,
            count: this.selectedCount,
        });
        if (this.commands[command].postAction) {
            this.commands[command].postData = JSON.stringify({
                bearer: Authorization.token,
                body: this.commands[command].clbk(event, this.commands[command]),
            });
        } else {
            this.commands[command].clbk(event, this.commands[command]);
        }
    };

    updateSelection(selectedGroupsCount = 0, selectedCount = 0, selectedVisibleEntities = [], isAllSelected = false) {
        this.selectedGroupsCount = selectedGroupsCount;
        this.selectedCount = selectedCount;

        if (this.commands) {
            for (const key in this.commands) {
                if (this.commands[key]) {
                    // Do not process separators.
                    if (this.commands[key].singleGroupSelection) {
                        // Command needs a single group.
                        this.commands[key].blocked = this.selectedGroupsCount !== 1;
                    } else if (this.commands[key].multiSelection) {
                        const allSelectionVisible = selectedVisibleEntities.length === this.selectedCount;

                        // Command needs at least a single element.
                        this.commands[key].blocked =
                            this.selectedCount === 0 ||
                            (typeof this.commands[key].shouldBlock === 'function' &&
                                this.commands[key].shouldBlock(
                                    selectedVisibleEntities,
                                    isAllSelected,
                                    allSelectionVisible
                                ));
                        if (typeof this.commands[key].shouldHide === 'function') {
                            this.commands[key].hidden = this.commands[key].shouldHide(
                                selectedVisibleEntities,
                                isAllSelected,
                                allSelectionVisible
                            );
                        }
                    } else if (this.commands[key].singleSelection) {
                        // Command needs a single element.
                        this.commands[key].blocked =
                            this.selectedCount !== 1 ||
                            (selectedVisibleEntities.length === this.selectedCount &&
                                typeof this.commands[key].shouldBlock === 'function' &&
                                this.commands[key].shouldBlock(selectedVisibleEntities));
                        if (
                            selectedVisibleEntities.length === this.selectedCount &&
                            typeof this.commands[key].shouldHide === 'function'
                        ) {
                            this.commands[key].hidden = this.commands[key].shouldHide(selectedVisibleEntities);
                        }
                    } else {
                        // Command doesn't need any selection.
                        this.commands[key].blocked = false;
                    }

                    if (typeof this.commands[key].getTooltip === 'function') {
                        this.commands[key].tooltip = this.commands[key].blocked
                            ? this.commands[key].getTooltip(selectedVisibleEntities)
                            : undefined;
                    }
                }
            }
        }
    }
}

function dropdownTreeCreator(section, sectionIndex, dropdown, commanding, onHide) {
    function dropdownOptionCreator(option, index) {
        const command = commanding.commands[option];
        return {
            id: `dropdown-option-${command.name}`,
            title: i18n(command.name),
            tooltip: i18n(command.tooltip),
            icon: command.icon && React.isValidElement(command.icon) ? command.icon : <EmptyIcon />,
            disabled: command.disabled || command.blocked,
            callback: (action, event) => {
                event.stopPropagation();
                commanding.execute(event, option);
                if (typeof onHide === 'function') {
                    onHide(event);
                }
            },
        };
    }

    function dropdownSubMenuCreator(command) {
        return {
            id: `dropdown-sub-menu-${command.name}`,
            icon: command.icon && React.isValidElement(command.icon) ? command.icon : <EmptyIcon />,
            title: i18n(command.name),
            tooltip: i18n(command.tooltip),
            disabled: command.disabled || command.blocked,
            placement: 'left',
            subMenu: [
                {
                    header: i18n(command.header || command.name),
                    items: command.commands.flat().map((option, index) => dropdownOptionCreator(option, index)),
                },
            ],
        };
    }

    return {
        header: sectionIndex === 0 ? i18n(dropdown.header || dropdown.name) : undefined,
        items: section
            .filter((option) => !!commanding.commands[option] && !commanding.commands[option].hidden)
            .flatMap((option, commandIndex) => {
                const command = commanding.commands[option];
                return command.commands ? dropdownSubMenuCreator(command) : dropdownOptionCreator(option, commandIndex);
            }),
    };
}
//-----------------------------------------------------------------------------
// COMMANDING DROPDOWN
//-----------------------------------------------------------------------------
const CommandingDropdown = React.forwardRef((props, ref) => {
    const [show, setShow] = useState(false);

    useImperativeHandle(ref, () => ({
        showCommandingDropdown: (show) => setShow(show),
    }));

    function convertDropdowns(dropdowns) {
        return dropdowns.flatMap((dropdown) => convertDropdownContent(dropdown));
    }

    function convertDropdownContent(dropdown) {
        if (props.commanding?.specialContext) {
            props.commanding.attachCommand('FILTER', false, false, {
                name: 'FILTER',
                icon: <IconActionFilter fill="currentcolor" />,
                commands: props.commanding.specialContext.layout,
                header: props.commanding.specialContext.header,
                disabled: props.commanding.specialContext.disabled,
            });
        }

        const MAIN_DROPDOWN =
            dropdown === undefined
                ? []
                : Array.isArray(dropdown.layout)
                ? dropdown.layout
                : dropdown.layout[dropdown.layout[props.groupingOptionId] !== undefined ? props.groupingOptionId : 0];

        const DYNAMIC_LAYOUT = [
            ...(dropdown?.dynamicLayout
                ? Array.isArray(dropdown.dynamicLayout)
                    ? dropdown.dynamicLayout
                    : dropdown.dynamicLayout[
                          dropdown.layout[props.groupingOptionId] !== undefined ? props.groupingOptionId : 0
                      ]
                : []),

            ...(props.commanding?.specialContext ? ['FILTER'] : []),
        ];

        return [...MAIN_DROPDOWN, ...(DYNAMIC_LAYOUT.length > 0 ? [DYNAMIC_LAYOUT] : [])].map((section, sectionIndex) =>
            dropdownTreeCreator(section, sectionIndex, dropdown, props.commanding, props.onHide)
        );
    }

    return !(props.target && props.commanding?.dropdowns) ? null : (
        <Dropdown
            id="dropdown-commanding"
            content={convertDropdowns(props.commanding.dropdowns)}
            show={show}
            getUnTargetObjects={() => (props.target?.contextElement ? props.target.contextElement : props.target)}
            onHide={props.onHide}
            placement="right"
            referenceObject={props.target}
            floatingElementPortalTarget={props.floatingElementPortalTarget}
        />
    );
});

// COMMANDING DROPDOWN - PROPERTIES TYPES
CommandingDropdown.propTypes = {
    show: PropTypes.bool,

    target: PropTypes.shape({
        getBoundingClientRect: PropTypes.func,
        contextElement: PropTypes.object,
    }),

    onHide: PropTypes.func,
};

// COMMANDING DROPDOWN - PROPERTIES DEFAULT VALUES
CommandingDropdown.defaultProps = {
    show: false,

    target: {
        getBoundingClientRect: null,
        contextElement: null,
    },

    onHide: null,
};

function createIncidentCommanding() {
    return BACKEND.post('incident/current', {}, this.props.componentUuid)
        .onStatus(404, () => {
            return Promise.resolve(null);
        })
        .success((currentIncident) => {
            return BACKEND.post(
                'incidents/0',
                {
                    sortOrders: [{ column: 'lastUpdate', ascend: false }],
                    pageSize: 3,
                    requiredFields: ['id', 'name'],
                    localFilters: {
                        filterTree: {
                            status: { NE: window.serverInfo.constants.incidentStatus.deleted },
                        },
                    },
                },
                this.props.componentUuid
            )
                .success(({ entities }) => {
                    const icons = [
                        <IconNumericOne fill="currentcolor" />,
                        <IconNumericTwo fill="currentcolor" />,
                        <IconNumericThree fill="currentcolor" />,
                    ];

                    this.props.commanding.attachCommand('CREATE_INCIDENT', false, false, {
                        name: 'CREATE_INCIDENT',
                        clbk: this.props.commanding.toolbar.attachIncidentCommands.onCreateIncident,
                        icon: <IconNumericNew fill="currentcolor" />,
                        singleSelection: true,
                    });

                    this.props.commanding.attachCommand('ADD_TO_CURRENT_INCIDENT', false, false, {
                        name: 'ADD_TO_CURRENT_INCIDENT',
                        clbk: () =>
                            this.props.commanding.toolbar.attachIncidentCommands.onAddToIncident(currentIncident),
                        icon: <IconTimeRunning fill="currentcolor" />,
                        singleSelection: true,
                        disabled: currentIncident === null,
                    });

                    this.props.commanding.attachCommand('SELECT_INCIDENT_TO_ADD_TO', false, false, {
                        name: 'SELECT_INCIDENT_TO_ADD_TO',
                        clbk: this.openIncidentSelectionModal,
                        singleSelection: true,
                        icon: <IconMenuMoreVertical fill="currentcolor" />,
                        disabled: entities.length === 0,
                    });

                    this.props.commanding.attachCommand('ADD_TO_RECENT_INCIDENT', false, false, {
                        name: 'ADD_TO_RECENT_INCIDENT',
                        singleSelection: true,
                        icon: <IconTimeHistory fill="currentcolor" />,
                        disabled: entities.length === 0,
                        commands:
                            entities.length > 0
                                ? [
                                      [
                                          ...entities?.slice(0, 3).map((incident, i) => {
                                              const icon = icons[i];

                                              this.props.commanding.attachCommand(
                                                  `INCIDENT_${incident.id}`,
                                                  false,
                                                  false,
                                                  {
                                                      name: incident.name,
                                                      clbk: () =>
                                                          this.props.commanding.toolbar.attachIncidentCommands.onAddToIncident(
                                                              incident
                                                          ),
                                                      icon,
                                                      singleSelection: true,
                                                  }
                                              );
                                              return `INCIDENT_${incident.id}`;
                                          }),
                                      ],
                                  ]
                                : [],
                    });

                    this.props.commanding.attachCommand(
                        this.props.commanding.toolbar.attachIncidentCommands.attachToCommand,
                        false,
                        false,
                        {
                            ...this.props.commanding.commands[
                                this.props.commanding.toolbar.attachIncidentCommands.attachToCommand
                            ],
                            commands: [
                                [
                                    'CREATE_INCIDENT',
                                    'ADD_TO_CURRENT_INCIDENT',
                                    'ADD_TO_RECENT_INCIDENT',
                                    'SELECT_INCIDENT_TO_ADD_TO',
                                ],
                            ],
                            singleSelection: true,
                        }
                    );
                    return this.commanding?.toolbar.attachIncidentCommands.attachToCommand;
                })
                .execute();
        })
        .execute();
}

//-----------------------------------------------------------------------------
// COMMANDING TOOLBAR
//-----------------------------------------------------------------------------

const DropdownCreator = React.forwardRef((props, ref) => {
    const optionsName = i18n(props.options.name);

    return (
        <Dropdown
            id={`dropdown-${props.id}`}
            reference={
                <DropdownButton
                    id={props.id}
                    title={optionsName}
                    disabled={props.options.disabled || props.options.blocked}
                />
            }
            placement="top"
            content={props.options.commands.map((section, sectionIndex) =>
                dropdownTreeCreator(section, sectionIndex, props.options, props.commanding, props.onHide)
            )}
        />
    );
});

function ButtonWithTooltip(props) {
    return (
        <Tooltip tooltipContent={props.tooltip} disabled={!props.tooltip}>
            <div>
                <Button
                    id={props.id}
                    disabled={props.disabled}
                    onClick={props.onClick}
                    type={props.type}
                    text={props.text}
                />
            </div>
        </Tooltip>
    );
}

class CommandingToolbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postData: {},
            incidentCommands: null,
        };
    }

    componentDidMount() {
        if (this.props.commanding?.toolbar?.attachIncidentCommands) {
            createIncidentCommanding.call(this).then((incidentCommands) => {
                this.setState({ incidentCommands });
            });
        }
    }

    getToolbarTemplate = (side, command, index) => {
        const options = this.props.commanding.commands[command];

        if (!options) {
            /**
             * wworkaround to avoid rare internal FE errors on very fast reloads
             **/
            return <Fragment></Fragment>;
        }

        const optionsName = i18n(options.name);

        return (
            <Tooltip tooltipContent={options.tooltip} disabled={!options.tooltip}>
                <div>
                    {options.commands ? (
                        <DropdownCreator
                            id={`commanding-toolbar-${side}-button-${index}`}
                            options={options}
                            commanding={this.props.commanding}
                        />
                    ) : (
                        // Plain button template - this functionality support simple commands and rules IMPORT/EXPORT commands!
                        <Fragment>
                            {!options.fileUpload && !options.postAction && !options.checkBox && (
                                <Button
                                    id={`commanding-toolbar-${side}-button-${index}`}
                                    text={optionsName}
                                    disabled={options.disabled || options.blocked}
                                    onClick={(event) => {
                                        this.props.commanding.execute(event, command);
                                    }}
                                    type={options.type ? options.type : 'secondary'}
                                />
                            )}
                            {options.checkBox && (
                                <FormGroup
                                    content={
                                        <Checkbox
                                            id={`commanding-toolbar-${side}-checkbox-${index}`}
                                            onChange={options.clbk}
                                            checked={options.checked}
                                            disabled={options.disabled}
                                            text={<span className="cell-text">{optionsName}</span>}
                                            testDataLabel="eid-checkbox"
                                        />
                                    }
                                />
                            )}
                            {options.fileUpload && (
                                <FileUploader
                                    text={optionsName}
                                    disabled={options.disabled}
                                    accept={options.fileUpload}
                                    onChange={options.clbk}
                                />
                            )}
                            {!options.fileUpload && options.postAction && (
                                /* this is a bit tricky - user callback is to set command.postData, then setState to refresh <form>! */
                                <form method="post" action={options.postAction} encType="text/plain">
                                    <Button
                                        id={`commanding-toolbar-${side}-button-${index}`}
                                        text={optionsName}
                                        disabled={options.disabled}
                                        onClick={(event) => {
                                            this.props.commanding.execute(event, command);
                                            this.setState({ postData: options.postData });
                                        }}
                                        type="secondary"
                                    />
                                    <input style={{ display: 'none' }} name={this.state.postData} />
                                </form>
                            )}
                        </Fragment>
                    )}
                </div>
            </Tooltip>
        );
    };

    openIncidentSelectionModal = () => {
        this.setState({ showIncidentSelectionModal: true });
    };

    closeIncidentSelectionModal = () => {
        this.setState({ showIncidentSelectionModal: false });
    };

    handleAddToIncident = (incident) => {
        this.props.commanding.toolbar.attachIncidentCommands.onAddToIncident(incident);
        this.closeIncidentSelectionModal();
    };

    getResponsiveToolbarTemplate = (side, command, index) => {
        const options = this.props.commanding.commands[command];

        if (!options) {
            /**
             * workaround to avoid rare internal FE errors on very fast reloads
             **/
            return <Fragment></Fragment>;
        }

        const optionsName = i18n(options.name);
        const tooltip = options.tooltip ? options.tooltip : undefined;

        /**
         * IMPORTANT! DOES NOT SUPPORT IMPORT AND EXPORT
         *
         * it's tricky, this component below will be processed as an object in @uilib/business-components getDropdownContent
         * and its props will be used to create the items in dropdown-menu
         * intended for use with Button and Dropdown component from @uilib/business-components but we have additional stuff like tooltips
         * our tooltips will be displayed only on the expanded buttons panel,
         * we cannot display tooltips in the buttons collapsed in dropdown menu
         */

        return options.commands ? (
            <Dropdown
                id={`dropdown-commanding-toolbar-${side}-button-${index}`}
                reference={(ref, toggle) => (
                    <div
                        /** Props just for dropdown creator */
                        id={`commanding-toolbar-${side}-button-container-${index}`}
                        title={optionsName}
                        disabled={options.disabled || options.blocked}
                    >
                        <Tooltip tooltipContent={options.tooltip} disabled={!options.tooltip}>
                            <div>
                                <DropdownButton
                                    ref={ref}
                                    toggle={toggle}
                                    id={`commanding-toolbar-${side}-buttonz-${index}`}
                                    title={optionsName}
                                    disabled={options.disabled || options.blocked}
                                />
                            </div>
                        </Tooltip>
                    </div>
                )}
                placement="top"
                content={options.commands.map((section, sectionIndex) =>
                    dropdownTreeCreator(section, sectionIndex, options, this.props.commanding)
                )}
            />
        ) : (
            <ButtonWithTooltip
                id={`commanding-toolbar-${side}-button-${index}`}
                tooltip={tooltip}
                text={optionsName}
                disabled={options.disabled || options.blocked}
                onClick={(event) => {
                    this.props.commanding.execute(event, command);
                }}
                type={options.type ? options.type : 'secondary'}
            />
        );
    };

    render() {
        if (!this.props.commanding?.toolbar) {
            return null;
        }

        const CONTEXT_MENU_BUTTON = !this.props.groupingOptionId
            ? this.props.commanding.toolbar?.contextMenu
            : this.props.commanding.toolbar?.groupContextMenu[
                  this.props.commanding.toolbar.groupContextMenu[this.props.groupingOptionId] !== undefined
                      ? this.props.groupingOptionId
                      : 0
              ];

        const sectionLeftButtons = [];

        if (this.props.commanding.toolbar.contextMenu && this.props.commanding.context?.attachToCommand === undefined) {
            sectionLeftButtons.push(this.getResponsiveToolbarTemplate('left', CONTEXT_MENU_BUTTON, 'context-menu'));
        }

        this.props.commanding.toolbar.left?.forEach((command, index) => {
            if (
                this.props.commanding.toolbar.contextMenu &&
                this.props.commanding.context?.attachToCommand !== undefined &&
                this.props.commanding.context.attachToCommand === command
            ) {
                sectionLeftButtons.push(this.getResponsiveToolbarTemplate('left', CONTEXT_MENU_BUTTON, 'context-menu'));
            } else if (!this.props.commanding.commands[command].hidden) {
                sectionLeftButtons.push(
                    this.getResponsiveToolbarTemplate(
                        'left',
                        this.state.incidentCommands &&
                            this.props.commanding.toolbar.attachIncidentCommands?.attachToCommand === command
                            ? this.state.incidentCommands
                            : command,
                        index
                    )
                );
            }
        });

        const sectionRightButtons = [];
        this.props.commanding.toolbar.right?.forEach((command, index) => {
            if (!this.props.commanding.commands[command].hidden) {
                sectionRightButtons.push(
                    this.getResponsiveToolbarTemplate(
                        'left',
                        this.state.incidentCommands &&
                            this.props.commanding.toolbar.attachIncidentCommands?.attachToCommand === command
                            ? this.state.incidentCommands
                            : command,
                        index
                    )
                );
            }
        });

        return (
            <Fragment>
                {this.props.commanding.toolbar.attachIncidentCommands && (
                    <IncidentSelectionModal
                        id="ei-incident-selection"
                        openIncidentSelectionModal={this.openIncidentSelectionModal}
                        closeIncidentSelectionModal={this.closeIncidentSelectionModal}
                        onAddToIncident={this.handleAddToIncident}
                        showIncidentSelectionModal={this.state.showIncidentSelectionModal}
                    />
                )}
                <Spacer type="py-2" className="ei-toolbar">
                    <div className="toolbar-section-left">
                        <ButtonGroup
                            testDataLabel="ei-toolbar"
                            className="toolbar-buttons-group"
                            dropdownPlacement="top"
                            buttons={[sectionLeftButtons, sectionRightButtons]}
                            orientation="left"
                        />
                    </div>
                    {this.props.commanding.toolbar.rightStatic && (
                        <div className="toolbar-section-right">
                            {this.props.commanding.toolbar.rightStatic
                                ?.filter((command) => !this.props.commanding.commands[command].hidden)
                                .map((command, index) => (
                                    <Spacer key={`toolbar-right-button-${index}`} type="py-2 pr-4">
                                        {this.getToolbarTemplate('right', command, index)}
                                    </Spacer>
                                ))}
                        </div>
                    )}
                </Spacer>
            </Fragment>
        );
    }
}

CommandingToolbar = withBackend(CommandingToolbar);

// COMMANDING TOOLBAR - PROPERTIES TYPES
CommandingToolbar.propTypes = {};

// COMMANDING TOOLBAR - PROPERTIES DEFAULT VALUES
CommandingToolbar.defaultProps = {};

export { CommandingContextBasic, CommandingContextSelection, CommandingToolbar, CommandingDropdown };

import Theme from '@uilib/business-components/_utils/theme';
import Select from '@uilib/business-components/Select';
import { SelectAsync, SelectAsyncCreatable, SelectCreatable } from '@uilib/business-components/Select/Select';

import i18n from 'Services/i18n';
import './index.scss';

function loadOptionsWrapper(loadOptions) {
    return loadOptions === undefined
        ? undefined
        : async function (inputValue, callback) {
              const options = await loadOptions(inputValue, callback);
              return Promise.resolve(
                  options.map((option) => ({
                      ...option,
                      label: option.label,
                  }))
              );
          };
}

function formatCreateLabelWrapper(formatCreateLabel) {
    return formatCreateLabel === undefined
        ? undefined
        : function (inputValue) {
              return i18n(formatCreateLabel(inputValue));
          };
}

function SelectWrapper(props) {
    return (
        <Theme.Provider
            theme={{
                Tag: {
                    background: {
                        active: 'var(--ui-color-primaryBackground)',
                    },
                },
            }}
        >
            <Select
                {...props}
                options={props.options.map((option) => ({
                    ...option,
                    label: i18n(option.label),
                }))}
                value={
                    Array.isArray(props.value)
                        ? props.value.map((v) => ({
                              ...v,
                              label: i18n(v.label),
                          }))
                        : props.value
                        ? {
                              ...props.value,
                              label: i18n(props.value && props.value.label),
                          }
                        : undefined
                }
                placeholder={i18n(props.placeholder || 'SELECT_WITH_DOTS')}
                maxSelectedText={i18n(props.maxSelectedText || 'MAXIMUM_NUMBER_OF_OPTIONS_SELECTED')}
            />
        </Theme.Provider>
    );
}

function SelectAsyncWrapper(props) {
    return (
        <SelectAsync
            {...props}
            loadOptions={props.loadOptions && loadOptionsWrapper(props.loadOptions)}
            placeholder={i18n(props.placeholder || 'SELECT_WITH_DOTS')}
            maxSelectedText={i18n(props.maxSelectedText || 'MAXIMUM_NUMBER_OF_OPTIONS_SELECTED')}
        />
    );
}

function SelectCreatableWrapper(props) {
    return (
        <SelectCreatable
            {...props}
            formatCreateLabel={formatCreateLabelWrapper(props.formatCreateLabel)}
            placeholder={i18n(props.placeholder || 'SELECT_WITH_DOTS')}
            maxSelectedText={i18n(props.maxSelectedText || 'MAXIMUM_NUMBER_OF_OPTIONS_SELECTED')}
        />
    );
}

function SelectAsyncCreatableWrapper(props) {
    return (
        <SelectAsyncCreatable
            {...props}
            loadOptions={loadOptionsWrapper(props.loadOptions)}
            formatCreateLabel={formatCreateLabelWrapper(props.formatCreateLabel)}
            placeholder={i18n(props.placeholder || 'SELECT_WITH_DOTS')}
            maxSelectedText={i18n(props.maxSelectedText || 'MAXIMUM_NUMBER_OF_OPTIONS_SELECTED')}
        />
    );
}

SelectWrapper.Async = SelectAsyncWrapper;
SelectWrapper.Creatable = SelectCreatableWrapper;
SelectWrapper.AsyncCreatable = SelectAsyncCreatableWrapper;

export default SelectWrapper;

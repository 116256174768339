import { useState, useEffect } from 'react';
import i18n from 'Services/i18n';

import Progress from 'Bricks/Progress';
import FormGroup from 'uilib-wrappers/form-group';
import Text from 'uilib-wrappers/text';

import Textarea from '@uilib/business-components/Textarea/Textarea';
import Button from 'uilib-wrappers/button';
import ConfirmationModal from 'uilib-wrappers/confirmation-modal';
import Spacer from '@uilib/core/Spacer/Spacer';
import summarize from './summarize';

const REOPEN_MODAL_ID = 'eid-reopen-modal';
const MAX_COMMENTS_LENGTH = window.serverInfo.dbColumnsLength.comments.commentValue;
const INCIDENT_STATUS = window.serverInfo.constants.incidentStatus;

function ReopenModal(props) {
    const [comment, setComment] = useState('');
    const [isLoading, setIsLoading] = useState(null);

    function handleCommentChange(comment) {
        setComment(comment);
    }

    function handleReopen() {
        if (isLoading) {
            return;
        }

        setIsLoading(true);
        const newComment = comment?.trim();
        props.changeStatus(INCIDENT_STATUS.open, { comment: newComment }).then((statusSuccess) => {
            summarize({
                ...(statusSuccess !== null && {
                    status: { success: statusSuccess, value: INCIDENT_STATUS.open },
                    comment: { success: statusSuccess, value: newComment },
                }),
            });
            props.close();
            props.reload();
        });
    }

    useEffect(() => {
        setComment('');
    }, [props.entities]);

    useEffect(() => {
        if (props.show) {
            setIsLoading(false);
        }
    }, [props.show]);

    return (
        <ConfirmationModal
            id={REOPEN_MODAL_ID}
            type="question"
            show={props.show}
            onDispose={props.close}
            message="DO_YOU_REALLY_WANT_TO_REOPEN_THIS_INCIDENT"
            buttons={[
                <Button
                    id="eid-button-reopen-ok"
                    key={`eid-button-reopen-ok-${isLoading}`}
                    type="primary"
                    text="OK"
                    onClick={handleReopen}
                    disabled={isLoading}
                />,
                <Button
                    id="eid-button-reopen-cancel"
                    key="eid-button-reopen-cancel"
                    type="secondary"
                    text="CANCEL"
                    onClick={props.close}
                />,
            ]}
        >
            <Text>INCIDENT_STATUS_WILL_CHANGE_TO_OPEN</Text>
            <Spacer type="py-2 mt-2">
                <FormGroup
                    labelText="COMMENT"
                    optionalText="OPTIONAL"
                    content={
                        <Textarea
                            value={comment}
                            onChange={handleCommentChange}
                            maxHeight={250}
                            maxLength={MAX_COMMENTS_LENGTH}
                            placeholder={i18n('ENTER_OPTIONAL_COMMENT_HERE_MAX_CHARACTERS', {
                                count: MAX_COMMENTS_LENGTH,
                            })}
                        />
                    }
                />
            </Spacer>
            <Progress showProgress={isLoading} />
        </ConfirmationModal>
    );
}

export default ReopenModal;

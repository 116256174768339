import { createContext } from 'react';

const defaultAppContextValue = {
    userUuid: null,
    setUserUuid: () => {},
    username: null,
    setUsername: () => {},
    users: [],
    setUsers: () => {},
    settings: {},
    setSettings: () => {},
    appState: {},
};

export const AppContext = createContext(defaultAppContextValue);

import IconNumericGreq from '@uilib/assets-business-icons/IconNumericGreq';
import IconNumericLeeq from '@uilib/assets-business-icons/IconNumericLeeq';
import IconNumericEq from '@uilib/assets-business-icons/IconNumericEq';
import IconNumericNoteq from '@uilib/assets-business-icons/IconNumericNoteq';

export const OPERATOR = {
    NE: 'NE',
    EQ: 'EQ',
    LE: 'LE',
    GE: 'GE',
    NOTLIKE: 'NOTLIKE',
    LIKE: 'LIKE',
    GT: 'GT',
    LT: 'LT',
};

export const BLANK_OPTIONS = {
    NONE: 'NONE',
    ANY: 'ANY',
    UNKNOWN: 'UNKNOWN',
    KNOWN: 'KNOWN',
};

export const BLANK_OPTIONS_FILTER = {
    [BLANK_OPTIONS.NONE]: { label: 'NONE', operator: OPERATOR.EQ, value: '' },
    [BLANK_OPTIONS.ANY]: { label: 'ANY', operator: OPERATOR.NE, value: '' },
    [BLANK_OPTIONS.UNKNOWN]: { label: 'UNKNOWN', operator: OPERATOR.EQ, value: null },
    [BLANK_OPTIONS.KNOWN]: { label: 'KNOWN', operator: OPERATOR.NE, value: null },
};

export const OPERATOR_ICONS = {
    GE: <IconNumericGreq fill="currentcolor" />,
    LE: <IconNumericLeeq fill="currentcolor" />,
    EQ: <IconNumericEq fill="currentcolor" />,
    NE: <IconNumericNoteq fill="currentcolor" />,

    LIKE: <IconNumericEq fill="currentcolor" />,
    NOTLIKE: <IconNumericNoteq fill="currentcolor" />,
};

import IconLetterI from '@uilib/assets-business-icons/IconLetterI';
import IconActionDelete from '@uilib/assets-business-icons/IconActionDelete';
import IconUserStatus from '@uilib/assets-business-icons/IconUserStatus';
import IconObjectTags from '@uilib/assets-business-icons/IconObjectTags';
import IconArrowRefresh from '@uilib/assets-business-icons/IconArrowRefresh';

import { Navigator } from 'Services';
import { BACKEND } from 'Services/backend';
import toast from '@uilib/business-components/ToastContainer/Toast';
import i18n from 'Services/i18n';
import { createSimpleSelectionBody } from 'Bricks/Helpers';

import { EVENT, EventNames } from 'Services/Eventing';
import { accessGroupSubmenu, showAffectedRowsInfo } from 'Bricks/Helpers';
import IncidentCommandingToolbar from './incident-commanding-toolbar';

const COMMANDING_MODE = {
    GRID: 0,
    DETAILS: 1,
    LIMITED_DETAILS: 2,
};

const INCIDENT_STATUS = window.serverInfo.constants.incidentStatus;
const IS_EPXINCIDENTMANAGEMEN_ENABLED = window.serverInfo.isEpxIncidentManagement;

function createIncidentesCommanding({
    accessGroupSubmenuClbk,
    tagsEditOpen,
    getIncidentInfo,
    mode,
    reload,
    componentUuid,
    onStatusAssigneeModalOpen,
    onReopenModalOpen,
    onAddComment,
    onRemediate,
}) {
    const clbkAction = (event, clbk) => {
        if (mode === COMMANDING_MODE.GRID) {
            this.gridApi.applyOnSingleSelection((entity) =>
                clbk(event, {
                    incidentId: entity.id,
                })
            );
        } else {
            const { incidentId } = getIncidentInfo();
            clbk?.(event, {
                incidentId,
            });
        }
    };

    function updateBackend(method, uri, { incidentId }, clbk) {
        BACKEND[method](uri, createSimpleSelectionBody([incidentId]), componentUuid)
            .success((response) => clbk(response))
            .execute();
    }

    const clbkWithBackend = (method, uri, clbk) => {
        if (mode === COMMANDING_MODE.GRID) {
            this.gridApi.updateBackendWithSelection(method, uri, clbk);
        } else {
            updateBackend(method, uri, getIncidentInfo(), clbk);
        }
    };

    const goToIncidentDetails = (event, { incidentId }) => {
        Navigator.go(event, 'console.incident.graph', { incidentId });
    };

    const makeCurrentIncident = (event, { incidentId }) => {
        BACKEND.post(`incident/current/${incidentId}`, {}, componentUuid).success(reload).execute();
    };

    const changeStatus = (status, clbk) => {
        return () => {
            clbkWithBackend('post', `incidents/status/${status}`, (response, selectedCount = 1) => {
                clbk ? clbk() : reload();
                if (response && response.affectedRows !== undefined && response.affectedRows < selectedCount) {
                    showAffectedRowsInfo(selectedCount, response.affectedRows);
                } else {
                    toast.success(i18n('STATUS_CHANGED'), {
                        autoClose: 3000,
                    });
                }
            });
        };
    };

    const edit = (event, { incidentId }) => {
        Navigator.go(event, 'console.incidentEdit', { incidentId });
    };

    const deleteIncident = () => {
        const is_grid = mode === COMMANDING_MODE.GRID;
        const msg = IS_EPXINCIDENTMANAGEMEN_ENABLED
            ? is_grid
                ? 'CANCEL_SELECTED_INCIDENT_QUESTION'
                : 'CANCEL_INCIDENT_QUESTION'
            : is_grid
            ? 'DELETE_SELECTED_INCIDENT_QUESTION'
            : 'DELETE_INCIDENT_QUESTION';
        EVENT.publish(EventNames.SIMPLE_ALERT_EVENT, {
            type: 'question',
            message: msg,
            details: IS_EPXINCIDENTMANAGEMEN_ENABLED
                ? 'CANCEL_AN_INCIDENT_ARE_YOU_SURE_YOU_WANT_TO_CONTINUE'
                : 'DELETING_AN_INCIDENT_IS_A_PERMANENT_ACTION_ARE_YOU_SURE_YOU_WANT_TO_CONTINUE',
            buttons: [
                {
                    id: 'delete',
                    text: 'DELETE',
                    type: 'primary',
                    onClick: changeStatus(
                        INCIDENT_STATUS.deleted,
                        mode === COMMANDING_MODE.DETAILS || mode === COMMANDING_MODE.LIMITED_DETAILS
                            ? () => {
                                  Navigator.go(null, 'console.incidents');
                              }
                            : undefined
                    ),
                },
                {
                    id: 'cancel',
                    text: 'CANCEL',
                },
            ],
        });
    };

    function isStatusClosed([selectedEntity]) {
        return selectedEntity?.status === INCIDENT_STATUS.closed;
    }

    function mayExistClosed(selectedVisibleEntities, isAllSelected, allSelectionVisible) {
        return (
            !selectedVisibleEntities?.length ||
            !allSelectionVisible ||
            !selectedVisibleEntities?.every((entity) => entity.status !== INCIDENT_STATUS.closed)
        );
    }

    function mayNotAllBeClosed(selectedVisibleEntities, isAllSelected, allSelectionVisible) {
        return (
            !selectedVisibleEntities?.length ||
            !allSelectionVisible ||
            !selectedVisibleEntities?.every((entity) => entity.status === INCIDENT_STATUS.closed)
        );
    }

    function reopenOrDefaultChangeStatusIsAvailable(selectedVisibleEntities, isAllSelected, allSelectionVisible) {
        return (
            !mayNotAllBeClosed(selectedVisibleEntities, isAllSelected, allSelectionVisible) ||
            !mayExistClosed(selectedVisibleEntities, isAllSelected, allSelectionVisible)
        );
    }
    const context = {
        header: 'INCIDENTS',
        layout: [
            ...(mode === COMMANDING_MODE.GRID ? [['INCIDENTS_GO_TO_DETAILS']] : []),
            [
                'INCIDENTS_MAKE_CURRENT_INCIDENT',
                'CHANGE_STATUS_AND_ASSIGNEE',
                'CHANGE_STATUS_AND_ASSIGNEE_INFINITY',
                'REOPEN_INCIDENT',
                'INCIDENTS_CANCEL_OR_DELETE_INCIDENT',
            ],
            ['ACCESS_GROUP'],
        ],
    };

    const toolbar = {
        left: [
            ...(mode === COMMANDING_MODE.DETAILS || mode === COMMANDING_MODE.LIMITED_DETAILS
                ? ['INCIDENT_CONTEXT']
                : []),
            ...(mode === COMMANDING_MODE.DETAILS
                ? [
                      'INCIDENTS_REMEDIATION',
                      'INCIDENTS_COMMENT',
                      'INCIDENTS_EDIT',
                      'CHANGE_STATUS_AND_ASSIGNEE',
                      'REOPEN_INCIDENT',
                  ]
                : []),
            ...(mode === COMMANDING_MODE.GRID
                ? [
                      'INCIDENTS_MAKE_CURRENT_INCIDENT',
                      'CHANGE_STATUS_AND_ASSIGNEE',
                      'CHANGE_STATUS_AND_ASSIGNEE_INFINITY',
                      'REOPEN_INCIDENT',
                      'INCIDENTS_CANCEL_OR_DELETE_INCIDENT',
                  ]
                : []),
        ],
        right: [],
    };

    const commands = {
        INCIDENTS_REMEDIATION: {
            name: 'REMEDIATION',
            clbk: onRemediate,
            type: 'primary',
            singleSelection: true,
        },
        INCIDENTS_COMMENT: {
            name: 'COMMENT',
            clbk: onAddComment,
            singleSelection: true,
            shouldBlock: isStatusClosed,
            getTooltip: (selectedEntities) =>
                isStatusClosed(selectedEntities) ? 'CANNOT_EXECUTE_ACTION_ON_CLOSED_INCIDENT' : undefined,
        },
        INCIDENTS_EDIT: {
            name: 'EDIT',
            clbk: (event) => clbkAction(event, edit),
            singleSelection: true,
            shouldBlock: isStatusClosed,
            getTooltip: (selectedEntities) =>
                isStatusClosed(selectedEntities) ? 'CANNOT_EXECUTE_ACTION_ON_CLOSED_INCIDENT' : undefined,
        },
        INCIDENTS_GO_TO_DETAILS: {
            name: 'DETAILS',
            icon: <IconLetterI fill="currentcolor" />,
            clbk: (event) => clbkAction(event, goToIncidentDetails),
            singleSelection: true,
        },
        INCIDENTS_MAKE_CURRENT_INCIDENT: {
            name: 'MAKE_CURRENT_INCIDENT',
            icon: <IconLetterI fill="currentcolor" />,
            clbk: (event) => clbkAction(event, makeCurrentIncident),
            singleSelection: true,
        },
        INCIDENTS_CANCEL_OR_DELETE_INCIDENT: {
            name: IS_EPXINCIDENTMANAGEMEN_ENABLED ? 'CANCEL_INCIDENT' : 'DELETE_INCIDENT',
            icon: <IconActionDelete fill="currentcolor" />,
            clbk: deleteIncident,
            multiSelection: true,
        },
        REOPEN_INCIDENT: {
            name: 'REOPEN_INCIDENT',
            icon: <IconArrowRefresh fill="currentcolor" />,
            clbk: onReopenModalOpen,
            singleSelection: true,
            multiSelection: true,
            hidden: true,
            shouldBlock: mayNotAllBeClosed,
            shouldHide: mayNotAllBeClosed,
        },
        CHANGE_STATUS_AND_ASSIGNEE: {
            name: 'CHANGE_STATUS_AND_ASSIGNEE',
            icon: <IconUserStatus fill="currentcolor" />,
            clbk: () => onStatusAssigneeModalOpen(false),
            multiSelection: true,
            hidden: true,
            shouldBlock: mayExistClosed,
            shouldHide: mayExistClosed,
        },
        CHANGE_STATUS_AND_ASSIGNEE_INFINITY: {
            name: 'CHANGE_STATUS_AND_ASSIGNEE',
            icon: <IconUserStatus fill="currentcolor" />,
            clbk: () => onStatusAssigneeModalOpen(true),
            multiSelection: true,
            shouldBlock: reopenOrDefaultChangeStatusIsAvailable,
            shouldHide: reopenOrDefaultChangeStatusIsAvailable,
        },
        INCIDENTS_TAGS: {
            name: 'TAGS',
            icon: <IconObjectTags fill="currentcolor" />,
            clbk: tagsEditOpen,
            multiSelection: true,
        },
        ...accessGroupSubmenu(accessGroupSubmenuClbk),
        INCIDENTS_CONTEXT: {
            name: 'INCIDENTS',
            commands: [...context.layout, ['INCIDENTS_TAGS']],
        },
        INCIDENT_CONTEXT: {
            name: 'INCIDENT',
            commands: [...context.layout, ['INCIDENTS_TAGS']],
        },
    };

    return {
        commands,
        context,
        toolbar,
    };
}

export { IncidentCommandingToolbar, COMMANDING_MODE };
export default createIncidentesCommanding;

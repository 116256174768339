import { init, getStoredLanguage, LANGUAGES, setLanguageInHtmlDocument } from 'Services/i18n';
import i18next from 'i18next';
import Progress from 'Bricks/Progress';
import { storeActions } from '@uilib/core/_store/Store';
import { useUilibStore } from '@uilib/core/_store/hooks/useUilibStore';
import { useEffect } from 'react';

export default function LanguageManager(props) {
    const { dispatch } = useUilibStore();

    function changeLocale(locale) {
        dispatch({
            data: locale,
            type: storeActions.LOCALE_CHANGE,
        });
    }

    function defineLanguageProperty(callback) {
        Object.defineProperty(window, 'eiLocalization', {
            configurable: true,
            get: () => i18next.language,
            set: async (newLanguage) => {
                if (newLanguage !== i18next.language && newLanguage in LANGUAGES) {
                    await i18next.changeLanguage(newLanguage);
                    setLanguageInHtmlDocument(newLanguage);
                    window.localStorage.setItem('language', newLanguage);
                    callback();
                }
            },
        });
    }

    useEffect(() => {
        const language = getStoredLanguage();
        props.onLanguageInitialized(false);

        i18next.on('initialized', () => {
            changeLocale(LANGUAGES[language].locale);
            defineLanguageProperty(() => {
                window.location.reload();
            });
            document.titleManager?.update?.();
            props.onLanguageInitialized(true);
        });

        init(language);
    }, []);

    return !props.languageInitialized && <Progress dim instant showProgress />;
}

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TagsPanel from '@uilib/business-components/TagsPanel/TagsPanel';
import Select from 'uilib-wrappers/select';

import { BACKEND, withBackend } from 'Services/backend';

import { escapeRegExp } from 'Bricks/Helpers';
import i18n from 'Services/i18n';

function TagsEdit(props) {
    const [validationError, setValidationError] = useState();

    useEffect(() => {
        props.setValue(
            props.tags.map((tag) => ({ id: tag.id, value: tag.name, label: tag.localized_name || tag.name }))
        );
    }, [props.tags]);

    function handleLoadOptions(inputValue) {
        if (typeof props.setMenuPortalOnLoad === 'function') {
            // Now we are sure the modal is mounted - attach the select menu.
            props.setMenuPortalOnLoad();
        }
        const requestBody = {
            localFilters: {
                filterTree:
                    inputValue !== ''
                        ? {
                              OR: [
                                  {
                                      name: {
                                          LIKE: escapeRegExp(inputValue),
                                      },
                                  },
                                  {
                                      localized_name: {
                                          LIKE: escapeRegExp(inputValue),
                                      },
                                  },
                              ],
                          }
                        : {},
            },
            sortOrders: [{ column: 'name', ascend: true }],
        };

        return BACKEND.post('tags/0', requestBody, props.componentUuid)
            .success((response) => {
                return response.entities.map((tag) => ({
                    id: tag.id,
                    value: tag.name,
                    label: tag.localized_name || tag.name,
                }));
            })
            .execute();
    }

    function handleChange(value) {
        if (
            value?.length > 0 &&
            value[value.length - 1].value.length > window.serverInfo.dbColumnsLength['tags'].tag_name
        ) {
            props.setValue(value.slice(0, -1));
            props.handleLengthExceeded();
        } else {
            props.setValue(value || []);
        }
    }

    function containsComma(inputValue) {
        return inputValue.includes(',');
    }

    function containsDuplicate(inputValue, input, options) {
        const inputValueUpperCase = inputValue.toUpperCase();
        return [...options, ...input].some(({ label, value }) => {
            return label.toUpperCase() === inputValueUpperCase || value.toUpperCase() === inputValueUpperCase;
        });
    }

    function validateInput(inputValue, input, options) {
        const isEmpty = !(inputValue?.length > 0);
        const isComma = containsComma(inputValue);
        const isDuplicate = containsDuplicate(inputValue, input, options);

        setValidationError(
            isEmpty ? 'NO_OPTIONS' : isComma ? 'TAG_CAN_NOT_CONTAIN_COMMA' : isDuplicate ? 'TAG_ALREADY_EXIST' : null
        );

        return !isEmpty && !isComma && !isDuplicate;
    }

    function noOptionsMessage() {
        return i18n(validationError);
    }

    return (
        <TagsPanel testDataLabel="eid-tag">
            <Select.AsyncCreatable
                menuPortalTarget={props.menuPortalTarget}
                autoFocus
                isMulti
                cacheOptions
                defaultOptions
                loadOptions={handleLoadOptions}
                onChange={handleChange}
                value={props.value}
                disabled={props.disabled}
                isValidNewOption={validateInput}
                noOptionsMessage={noOptionsMessage}
                formatCreateLabel={(tagName) => i18n('CREATE_TAG', { tagName, interpolation: { escapeValue: false } })}
            />
        </TagsPanel>
    );
}
//-----------------------------------------------------------------------------
TagsEdit.propTypes = {
    tags: PropTypes.array.isRequired,
    handleLengthExceeded: PropTypes.func.isRequired,
    menuPortalTarget: PropTypes.instanceOf(Element),
    setMenuPortalOnLoad: PropTypes.func,
    disabled: PropTypes.bool,
};

//-----------------------------------------------------------------------------
TagsEdit.defaultProps = {
    menuPortalTarget: null,
    disabled: false,
};

export default withBackend(TagsEdit);
